//
// Config values
//

$platform-name: "postantenataldepressionsupportandinformationinc";
$assets-path: "../assets/";
$site-bleed: 20px;

//
// Colours
//
$brand-primary: #2c8aa0;
$brand-secondary: #349f88;
$grey: #242022;
$donate-colour: #e9866f;
// Create greyscale

// Utility colours

//
// Layout
//
$border-radius: 6px;

// Breakpoints

// Spacers
$spacer: 1rem;
$spacer-y: $spacer;
$spacer-x: $spacer;

//
// Misc
//
$body-colour: $grey;

//
// Transitions
//

//
// Logo
//
$logo: "logo.png";
$logo-width: 250px;
$logo-height: 80px;
$logo-absolute: true;
//
// Typography
//
$font-open-sans: "Open Sans", sans-serif;
$font-family-base: $font-open-sans;
$headings-font-family: "Montserrat", serif;
$headings-colour: $grey;
$font-weight-bold: 600;
// Links

// Icon font

//
// Buttons
//
$border-radius: 3px;
$btn-border-radius: $border-radius;
$btn-padding-x: 1em;
$btn-padding-y: 0.8em;
$btn-font-family: $headings-font-family;
$btn-font-weight: $font-weight-bold;
$btn-text-colour: #fff;
// Donate button
$donate-btn-colour: #fff;

//
// Social icons
//

$social-icons-border-radius: 100px;
$social-icons-width: 30px;
$social-icons-height: 30px;
$social-icons-font-size: 1rem;

$social-icons-background-colour: #989898;
$social-icons-colour: #fff;

// Social network colours - use these by changing $social-icons-use-brand-colours to true
$social-icons-gap: 10px;
// Header specific

// Footer specific social icons
$social-icons-footer-background-colour: transparent;

//
// Share this page
//

//
// Form elements
//

// Field groups

//
// Tables
//

//
// Card
//
$card-border-radius: $border-radius;
$card-heading-colour: #58595b;
$card-hover-image-opacity: 1;
$card-hover-image-scale: 1.1;
$card-hover-details-background-colour: $brand-primary;
$card-hover-heading-colour: white;
$card-border: 0;
$card-hover-summary-colour: white;
// Card text overlay

// Card text over

// Card side by side

// Card hover state

//
// Menu admin
//

// $menu-admin-enabled: false;
$menu-admin-login-link-enabled: false;
$menu-admin-manager-link-enabled: false;
$menu-admin-my-details-link-enabled: false;
$menu-admin-logout-link-enabled: false;
$basket-link-only-on-shop-pages: true;

//
// Header
//

// Header content

$header-content-margin-top: 1rem;
$header-content-margin-bottom: 2rem;
// Tagline
$tagline-enabled: true;
$tagline-colour: $brand-secondary;
// Main call to action
$cta-btn-gap: 20px;
$cta-btn-colour: white;

// $cta-padding-right-at-nav-breakpoint: 6.5em !default; // The gap we need to leave so the cta buttons clear the burger menu button after nav breakpoint

// Search
$header-search-input-max-width: 0;
$header-search-input-margin-right: 0;
$header-search-input-padding: 0px 0px;
$header-search-input-border-colour: #fff;
$header-search-button-background-colour: #989898;
$header-search-button-icon-colour: #fff;
$header-search-button-border-radius: 100px;
$header-search-input-margin-right: 5px;
$header-search-margin-right: 10px;
$header-search-button-width: 30px;
$header-search-button-height: 30px;
// Search - input

// Search - button

// Social icons

// Colours set above

//
// Sticky header
//
$header-sticky-enabled: true;
$header-sticky-background-colour: white;
$header-sticky-search-enabled: true;
$header-sticky-social-icons-enabled: true;
$header-sticky-header-content-margin-top: 0rem;
$header-sticky-header-content-margin-bottom: 0.5rem;
$header-sticky-logo-width: 145px;
$header-sticky-logo-height: 70px;
// Navigation
//

$nav-type: normal;
$nav-background-colour: transparent;
$nav-normal-align-items: left;
$nav-top-level-item-colour: #000;
$nav-font-family: $font-open-sans;
// Top level items
$nav-top-level-item-colour: #58595b;
$nav-top-level-item-font-weight: 600;
$nav-top-level-item-padding: 15px 25px;
// Submenus
$nav-submenu-item-colour: #58595b;
// Burger button

// Nav normal
$nav-normal-margin-bottom: 0;

//
// Carousel
//
$carousel-max-width: 100%;

$carousel-details-max-width: 500px;
$carousel-details-min-height: 100%;
$carousel-details-padding: 3rem;
$carousel-details-position-y: center;
$carousel-details-background-colour: transparent;

$carousel-read-more-background-colour: $brand-secondary;

$carousel-controls-button-background-colour: $carousel-details-background-colour;
$carousel-heading-colour: white;
$carousel-summary-colour: white;
$carousel-read-more-background-colour: transparent;
// Carousel controls (left-right buttons)
$carousel-controls-position-y: bottom;
$carousel-controls-position-x: right;
$carousel-controls-button-border-radius: 0;

// Carousel controls (left-right buttons)
$carousel-controls-position-y: center;
$carousel-controls-position-x: split;

// Carousel buttons (dots or tabs)
$carousel-dots-position-x: center;

// Carousel buttons dots
$carousel-dots-active-colour: white;

// Carousel buttons tabs

// Carousel after breakpoint

//
// Home intro
//
$home-intro-background-colour: white;
$home-intro-font-size: 1.3rem;
$home-intro-padding-y: $spacer * 1;
$home-intro-max-width: 980px;
$home-intro-margin-top: 3rem;
//
// Home features
//
$home-features-padding-bottom: $spacer * 2;
$home-features-padding-top: $spacer * 2;

//
// Impact stats
//
$impact-stats-background-colour: transparent;
$impact-stats-text-align: left;
$impact-stats-padding-y: $spacer * 3;
// Heading
$impact-stats-heading-colour: $brand-primary;
// Individual stat

// Figure
$impact-stats-figure-colour: $grey;
$impact-stats-figure-font-family: $font-open-sans;
$impact-stats-figure-font-size: 3em;
// Summary
$impact-stats-summary-font-size: 1rem;
$impact-stats-summary-margin-top: 20px;
//
// Home feeds
//
$home-feeds-background-colour: #ece9e9;
$home-feeds-padding-top: $spacer * 8;
// Feeds title
$feeds-title-colour: #333;
$feeds-title-text-align: left;
// Feed item

// Event feed items
$feed-item-event-start-date-enabled: false;
$feed-item-event-location-enabled: false;

// Twitter feed

//
// Footer
//
$footer-prefab: 5;
$footer-boxes-count: 4;
$footer-background-colour: #2671a3;
$footer-link-colour: white;
$footer-padding-y: $spacer * 6;
// Footer admin links

// Newsletter
$newsletter-heading-text-align: left;

$newsletter-background-colour: #2671a3;
$newsletter-button-background-colour: #2671a3;
$newsletter-button-colour: #fff;
$newsletter-button-background-colour: $brand-secondary;
// Newsletter hero - full width form above rest of footer

//
// Context container
//

// Donors list

//
// Posts
//

//
// Header text (container for breadcrumb and page title)
//

//
// Page title
//

//
// Sidebar
//

// Associated lists

//
// Listing
//

// Listed post settings

//
// Breadcrumb
//

//
// Blockquote
//

//
// Fundraising
//

// Find a fundraiser widget

// Top fundraisers

//
// Donation form
//

// Donation amounts

//
// Quick giving panel
//
$home-quick-giving-type: overlay;
$home-quick-giving-background-colour: rgba(#000, 0.7);
$home-quick-giving-type-overlay-gradient: linear-gradient(
  to left,
  rgba(0, 0, 0, 0) 40%,
  rgba(0, 0, 0, 1) 100%
);
//
// Home quick giving panel
//
$home-quick-giving-heading-colour: white;
$home-quick-giving-donate-btn-background-colour: $donate-colour;
$home-quick-giving-donation-amount-background-colour: rgba(#000, 0.2);
// Adjacent homepage quick giving

// Overlay homepage quick giving

//
// Totalisers
//

//
// Comments
//

//
// Blog details
//

//
// FAQs
//

//
// Shop
//

// Departments list

// Product-price

// Product post

//
// Subsite
//

// Subsite nav

//
// Cookie consent
//
