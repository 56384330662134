.menuMain ul {
  border-top: solid #e4e4e4 1px;
}

//carousel
.carousel.hasCaption .carouselSlideDetail {
  background: linear-gradient(
    217deg,
    rgba(45, 138, 160, 0.8),
    rgba(116, 199, 216, 0.8) 70.71%
  );
}

.carouselSlideHeading {
  @include heading-underline(
    $colour: white,
    $width: 50px,
    $height: 2px,
    $justify: left,
    $margin-top: 1rem,
    $margin-bottom: 0rem
  );
}

.carouselSlideReadMore {
  border: 1px solid white;
}

//intro
.homeIntro h3 {
  @include heading-underline(
    $colour: $brand-secondary,
    $width: 50px,
    $height: 2px,
    $justify: center,
    $margin-top: 1rem,
    $margin-bottom: 1rem
  );
}

//homeboxes

.homeBox1 .homeFeatureDetailsWrapper {
  height: 100%;
}

.homeBox1 .homeFeatureDetailsWrapper h2 {
  @include heading-underline(
    $colour: white,
    $width: 50px,
    $height: 2px,
    $justify: left,
    $margin-top: 1rem,
    $margin-bottom: 1rem
  );
}

// JK - Have to break this grid prefab a bit early here: https://www.bugherd.com/projects/173576/tasks/22
@media (min-width: map-get($breakpoints, sm)) and (max-width: 1024px) {
  .homeFeatures {
    display: flex;
    flex-wrap: wrap;

    .homeBox1 {
      width: 100%;
    }

    .homeBox2, .homeBox3 {
      flex: 1;
    }
  }
}

//impact header

.homefeaturecategory-homeboximpactstats td:first-child h4 {
  font-size: 2em;
}

.homefeaturecategory-homeboximpactstats td:first-child h2 {
  @include heading-underline(
    $colour: $brand-secondary,
    $width: 50px,
    $height: 2px,
    $justify: left,
    $margin-top: 1rem,
    $margin-bottom: 0rem
  );
}

.homefeaturecategory-homeboximpactstats {
  tbody {
    align-items: stretch;
  }
  tr {
    justify-content: flex-start;
  }
}

//quick giving
.homefeaturecategory-homeboxquickgiving
  .quickGivingPanel
  .formQuestion.donationAmount
  .donationAmountFigure {
  border: solid white 1px;
}

section.footerBox.footerBox1.NewsletterSign-up {
  padding-right: 80px;
}

.homefeaturecategory-homeboxquickgiving h2 {
  @include heading-underline(
    $colour: white,
    $width: 50px,
    $height: 2px,
    $justify: left,
    $margin-top: 1rem,
    $margin-bottom: 1rem
  );

  @media (max-width: 1024px) {
    &:after {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.homeFeed .feedsTitle {
  @include heading-underline(
    $colour: $brand-secondary,
    $width: 50px,
    $height: 2px,
    $justify: left,
    $margin-top: 1rem,
    $margin-bottom: 1rem
  );
}

.pageFooter h4 {
  @include heading-underline(
    $colour: white,
    $width: 50px,
    $height: 2px,
    $justify: left,
    $margin-top: 1rem,
    $margin-bottom: 1rem
  );
}

//aligning with logo
.pageHeader .headerContent > *:not(.mainLogo) {
  margin-top: 1rem;
  margin-bottom: 0rem;
}

//tagline
// .pageHeader .headerContent .tagline:before {
//   content: "\f095";
//   font-family: FontAwesome;
//   margin-right: 5px;
// }

.pageHeader .headerContent .tagline {
  text-align: right;
}

.tagline span {
  color: #58595b;
}

section.footerBox.footerBox3 img {
  vertical-align: middle;
  padding: 20px 10px;
}

//sticky header
@media (min-width: 1024px) {
  .pageHeader.sticky .menuMain {
    display: none;
  }
}